export const savingsPerksOverrides = `
.vp-text-container {  
  @media (max-width: 768px) {
    padding: 0rem 2rem 0rem;
  }
  @media (max-width: 1024px) {
    padding: 6rem 4rem 6rem;
  }
  @media (min-width: 1024px) {
    padding: 6rem 3.5rem 6rem;
  }
},
.vp-title {
  span {
    color: #A376FF;
  } 

  @media (max-width: 768px) {
    font-size: 24px;
    text-align: center;
  }
  @media (min-width: 768px) {
    font-size: 44px;
    text-align: right;
  }
}
`;

export const creditPerksOverrides = `
.vp-text-container {  
  @media (max-width: 768px) {
    padding: 0rem 2rem 0rem;
  }
  @media (max-width: 1024px) {
    padding: 6rem 4rem 6rem;
  }
  @media (min-width: 1024px) {
    padding: 6rem 3.5rem 6rem;
  }
},
.vp-title {
  @media (max-width: 768px) {
    font-size: 24px;
    text-align: center;
  }
  @media (min-width: 768px) {
    font-size: 44px;
    text-align: right;
  }
}
`;

export const perksOverrides = `
  .perks-image {
    picture img {
      border-radius: 20px;
    }
  }
`;
