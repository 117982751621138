import styled from 'styled-components';
import { tablet768 } from '@nubank/nuds-web/styles/breakpoints';

export const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #F2EEEE;
  padding: 2rem;

  ${tablet768`
    padding: 6rem 3.5rem;
  `}

  h2 {
    margin-bottom: 4rem;
    ${tablet768`
      margin-bottom: 5rem;
    `}
  }
`;

StyledSection.displayName = 'StyledSection';

