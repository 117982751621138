import styled from 'styled-components';
import Link from '@nubank/nuds-web/components/Link/Link';
import { desktop1024 } from '@nubank/nuds-web/styles/breakpoints';

export const StyledLink = styled(Link)`
  margin-bottom: 2.5rem;
  margin-top: 1rem;
  width: fit-content;

  ${desktop1024`
    margin-top: 1.5rem;
  `}
`;

StyledLink.displayName = 'LinkWrapper';

