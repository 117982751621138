import styled from 'styled-components';
import { tablet768, desktop1024 } from '@nubank/nuds-web/styles/breakpoints';

export const LinkWrapper = styled.li`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
  margin-bottom: 2.5rem;

  &:last-child {
    border-bottom: unset;
     margin-bottom: 2.5rem;
  }

  ${tablet768`
    border-bottom: unset;
    margin-top: 2.5rem;
  `}

  ${desktop1024`
    width: 90%;
  `}
`;

LinkWrapper.displayName = 'LinkWrapper';

