import styled from 'styled-components';

export const CardPicture = styled.picture`
  display: block;
  grid-area: picture;

  img {
    display: block;
    width: 100%;
  }

`;

CardPicture.displayName = 'CardPicture';
