import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

export const HeroBlurContainer = styled.div`
    position:fixed;
    bottom: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(8px);
    background: rgba(255 255 255 / 48%);
    ${breakpointsMedia({
    xs: css`
            width: 100%;
            height: 48px;`,
    md: css`
            width: 100%;
            height: 56px;`,
    lg: css`
            width: 100%;
            height: 72px;`,
  })}      
  
  p {
    font-style: normal;
    font-weight: 400;
    ${breakpointsMedia({
    xs: css`
            font-size: 10px;
            text-align: center;`,
    md: css`
            font-size: 14px;`,
    lg: css`
            font-size: 18px;`,
  })}         
  }
`;

HeroBlurContainer.displayName = 'HeroBlurContainer';
