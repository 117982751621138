import PropTypes from 'prop-types';

const HomePropType = {
  hero: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    heroImages: PropTypes.shape({
      lg: PropTypes.string,
      md: PropTypes.string,
      sm: PropTypes.string,
      xl: PropTypes.string,
      xs: PropTypes.string,
    }).isRequired,
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
};

export default HomePropType;
