import styled from 'styled-components';
import { tablet768, desktop1024 } from '@nubank/nuds-web/styles/breakpoints';

export const LinksWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  margin-right: 2rem;

  ${tablet768`
    margin-left: 4rem;
    margin-right: 4rem;
  `}

  ${desktop1024`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-left: 2rem;
    margin-right: 0;
    justify-content: space-between;
  `}
`;

LinksWrapper.displayName = 'LinksWrapper';

