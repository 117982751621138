import React from 'react';
import PropTypes from 'prop-types';
import Image from '@nubank/nuds-web/components/Image/Image';

import { StyledSection } from './styles/StyledSection';
import { CopyContainer } from './styles/CopyContainer';
import { StyledTypography } from './styles/StyledTypography';

const Blog = ({
  alt, blogImages, title,
}) => (
  <StyledSection>
    <Image
      srcSet={blogImages}
      loading="lazy"
      altIntlKey={alt}
      titleIntlKey={alt}
      webp
    />

    <CopyContainer>
      <StyledTypography
        intlKey={title}
        variant="heading2"
      />
    </CopyContainer>
  </StyledSection>
);

Blog.propTypes = {
  alt: PropTypes.string.isRequired,
  blogImages: PropTypes.shape({
    lg: PropTypes.string.isRequired,
    md: PropTypes.string.isRequired,
    xs: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
};

export default Blog;
