import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

const VerticalProductCardContainer = styled.div`
    ${breakpointsMedia({
    xs: css`
            .card {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                background-color: ${({ backgroundColor }) => backgroundColor};
                max-height: 100%;
                border-radius: 20px;
                overflow: hidden;
                padding: 56px 32px 40px;
                gap: 72px;
                margin-top: 40px;
                align-self: stretch;
                flex: none;
                flex-grow: 0;
            }

            .paragraphs {
                gap: 24px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            .paragraph {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 146%;
                letter-spacing: -0.01em;
                font-feature-settings: 'ss05' on;
                color: ${({ textColor }) => textColor};
            }

            .subtitle {
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 120%;
                letter-spacing: -0.02em;
                font-feature-settings: 'ss05' on;
                color: ${({ subtitleColor }) => subtitleColor};
                flex: none;
                order: 0;
                align-self: stretch;
                flex-grow: 0;
            }

            .heading {
                font-style: normal;
                font-weight: 500;
                font-size: 36px;
                line-height: 110%;
                letter-spacing: -0.03em;
                font-feature-settings: 'ss05' on;
                color: ${({ headingColor }) => headingColor};
                flex: none;
                order: 0;
                align-self: stretch;
                flex-grow: 0;
            }
        `,
    md: css`
            .card {
                height: 100%;
                padding: 56px 24px 44px;
                margin-top: 8px;
            }

            .subtitle {
                line-height: 132%;
            }

            .heading {
                font-size: 30px;
                line-height: 120%;
            }

            #show-product-btn {
                max-width: 333px;
                margin-bottom: 0;
                margin-top: auto;
            }
        `,
    lg: css`
            .card {
                padding: 56px 40px 40px;
                gap: 144px;
            }

            .paragraph {
                font-weight: 400;
                font-size: 18px;
                line-height: 132%;
            }

            .subtitle {
                font-size: 36px;
                line-height: 112%;
            }

            .heading {
                font-size: 48px;
                line-height: 116%;
            }
        `,
  })}
`;

export default VerticalProductCardContainer;
