import { css } from 'styled-components';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';
import Image from '@nubank/nuds-web/components/Image/Image';

import { CardPicture } from './styles/CardPicture';

const Perks = ({
  perksImages,
  alt,
  firstTitle,
  firstBody,
  secondTitle,
  secondBody,
  thirdTitle,
  thirdBody,
  violetTitle,
  backgroundColor,
  textVariant,
  styleOverride,
}) => {
  const { formatMessage } = useIntl();
  const imageAlt = useMemo(() => formatMessage({ id: alt }));

  return (
    <Box
      tag="section"
      display="grid"
      justifyContent="space-around"
      gridColumnGap={{ md: '3rem', lg: '4rem', xl: '4rem' }}
      gridTemplateColumns={{ lg: '1fr 1fr', md: '1fr 26rem' }}
      gridTemplateAreas={{
        xs: `"picture"
    "perks"`,
        md: '"picture perks"',
        lg: '"picture perks"',
      }}
      padding={{ xs: '4.5rem 1.5rem 7.5rem', md: '3rem 1.5rem 7.5rem', lg: '8rem 8rem 8rem' }}
      backgroundColor={backgroundColor}
      css={css`${styleOverride}`}
    >

      <CardPicture className="perks-image">

        <Image
          srcSet={perksImages}
          loading="lazy"
          alt={imageAlt}
          webp
        />
      </CardPicture>

      <Box
        gridArea="perks"
      >
        <Box
          marginTop={{ xs: '2rem', lg: '1rem' }}
        >
          <Typography
            variant="heading2"
            tag="h2"
            color="primary"
            colorVariant="default"
            intlKey={violetTitle}
          />
          <Typography
            variant="heading3"
            tag="h3"
            intlKey={firstTitle}
            gutterBottom={1.5}
          />
          <Typography
            variant={textVariant}
            intlKey={firstBody}
            color="black.defaultT70"
            gutterBottom={3.0}
          />
        </Box>
        <Box
          marginBottom={{ xs: '4rem', lg: '4.5rem' }}
        >
          <Typography
            variant="heading3"
            tag="h3"
            intlKey={secondTitle}
            gutterBottom={1.5}
          />
          <Typography
            css={css`strong { font-weight: bold;}`}
            variant={textVariant}
            intlKey={secondBody}
            color="black.defaultT70"
          />
        </Box>
        <Box>
          <Typography
            variant="heading3"
            tag="h3"
            intlKey={thirdTitle}
            gutterBottom={1.5}
          />
          <Typography
            variant={textVariant}
            intlKey={thirdBody}
            color="black.defaultT70"
          />
        </Box>
      </Box>
    </Box>
  );
};

Perks.defaultProps = {
  violetTitle: null,
  backgroundColor: 'white',
  textVariant: 'subtitle1',
  styleOverride: '',
};

Perks.propTypes = {
  alt: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  firstBody: PropTypes.string.isRequired,
  firstTitle: PropTypes.string.isRequired,
  perksImages: PropTypes.shape({
    lg: PropTypes.string.isRequired,
    md: PropTypes.string.isRequired,
    xs: PropTypes.string.isRequired,
  }).isRequired,
  secondBody: PropTypes.string.isRequired,
  secondTitle: PropTypes.string.isRequired,
  styleOverride: PropTypes.string,
  textVariant: PropTypes.string,
  thirdBody: PropTypes.string.isRequired,
  thirdTitle: PropTypes.string.isRequired,
  violetTitle: PropTypes.string,
};

export default Perks;
