import { ProductCardType } from '../../../components/ProductCard/ProductCard';
import { savingsPerksOverrides, creditPerksOverrides, perksOverrides } from '../styles/Overrides';

const ComponentDefaultProps = {
  cucuta: {
    hero: {
      title: 'MULTI_PRODUCT_HOME.HERO.TITLE',
      subtitle: 'MULTI_PRODUCT_HOME.HERO.SUBTITLE',
      alt: 'MULTI_PRODUCT_HOME.HERO.IMAGE.ALT',
      heroImages: {
        xs: 'multi-product-home/hero/hero-xs-1x.jpg',
        md: 'multi-product-home/hero/hero-md-1x.jpg',
        lg: 'multi-product-home/hero/hero-lg-1x.jpg',
      },
      form: {
        label: 'MULTI_PRODUCT_HOME.HERO.FORM.LABEL',
        placeholder: 'MULTI_PRODUCT_HOME.HERO.FORM.PLACEHOLDER',
        btnLabel: 'MULTI_PRODUCT_HOME.HERO.FORM.BUTTON_LABEL',
        btnStyleVariant: 'primary',
        validateDisabled: true,
      },
      specificColor: '#820AD1',
    },
    nuMessage: {
      title: 'CREDIT_CARD.MESSAGE.TEXT',
      spanColor: '#820AD1',
    },
    disclaimer: {
      message: 'MULTI_PRODUCT_HOME.HERO.BLUR.LABEL',
      show: false,
    },
    savingsProduct: {
      sectionColor: '#F2EEEE',
      productImages: {
        xs: 'multi-product-home/products/savings/savings-without-logos-xs-2x.jpg',
        md: 'multi-product-home/products/savings/savings-without-logos-md-2x.jpg',
        lg: 'multi-product-home/products/savings/savings-without-logos-lg-2x.jpg',
      },
      header1: 'MULTI_PRODUCT_HOME.PRODUCTS.SAVINGS.HEADER1',
      header2: 'MULTI_PRODUCT_HOME.PRODUCTS.SAVINGS.HEADER2',
      text: 'CUCUTA.PRODUCTS.SAVINGS.TEXT',
      productCardType: ProductCardType.IMAGE_RIGHT,
      btnLabel: 'MULTI_PRODUCT_HOME.PRODUCTS.SAVINGS.BUTTON',
      btnHref: 'MULTI_PRODUCT.MENU.SAVINGS.PATH',
      btnColor: 'black',
      color: '#A376FF',
      textColor: 'white',
    },
    savingsPerks: {
      alt: 'MULTI_PRODUCT_HOME.SAVINGS_VALUE_PROPOSAL.ALT',
      backGroungColor: '#F2EEEE',
      heroImages: {
        xs: 'multi-product-home/products/savings/perks/savings-perks.gif',
        md: 'multi-product-home/products/savings/perks/savings-perks.gif',
        lg: 'multi-product-home/products/savings/perks/savings-perks.gif',
      },
      title: 'MULTI_PRODUCT_HOME.SAVINGS_VALUE_PROPOSAL.TITLE',
      titleColor: 'rgba(0,0,0,0.64)',
      gridLargeOrder: '"valueProps divider picture"',
      hasDivider: true,
      styleOverride: savingsPerksOverrides,
    },
    creditsProduct: {
      productImages: {
        xs: 'multi-product-home/products/credits/creditsprd-xs.jpg',
        md: 'multi-product-home/products/credits/creditsprd-md.jpg',
        lg: 'multi-product-home/products/credits/creditsprd-lg.jpg',
      },
      header1: 'MULTI_PRODUCT_HOME.PRODUCTS.CREDITS.HEADER1',
      header2: 'MULTI_PRODUCT_HOME.PRODUCTS.CREDITS.HEADER2',
      text: 'CUCUTA.PRODUCTS.CREDIT-CARD.TEXT',
      productCardType: ProductCardType.IMAGE_LEFT,
      btnLabel: 'MULTI_PRODUCT_HOME.PRODUCTS.CREDITS.BUTTON',
      btnHref: 'MULTI_PRODUCT.MENU.CREDIT_CARD.PATH',
      btnColor: '#820AD1',
      color: '#820AD1',
      textColor: 'white',
    },
    creditsPerks: {
      alt: 'MULTI_PRODUCT_HOME.PRODUCTS.CREDITS.PERKS.ALT',
      backGroungColor: 'white.default',
      heroImages: {
        xs: 'multi-product-home/products/credits/perks/credit-card-perks-xs-1x.gif',
        md: 'multi-product-home/products/credits/perks/credit-card-perks-xs-1x.gif',
        lg: 'multi-product-home/products/credits/perks/credit-card-perks-xs-1x.gif',
      },
      imageSize: {
        xs: 150,
        md: 300,
        lg: 300,
      },
      title: 'MULTI_PRODUCT_HOME.PRODUCTS.CREDITS.PERKS.TITLE',
      titleColor: 'primary.default',
      gridLargeOrder: '"valueProps divider picture"',
      hasDivider: true,
      styleOverride: creditPerksOverrides,
    },
    reasons: {
      creditsParams: {
        headingTag: 'MULTI_PRODUCT_HOME.REASONS.CREDITS.HEADER',
        subtitleTag: 'MULTI_PRODUCT_HOME.REASONS.CREDITS.SUBTITLE',
        textTag: 'CUCUTA.REASONS.CREDITS.TEXT',
        buttonTag: 'MULTI_PRODUCT_HOME.REASONS.CREDITS.BUTTON',
        buttonUrlTag: 'MULTI_PRODUCT.MENU.CREDIT_CARD.PATH',
        buttonColor: 'primary',
        buttonVariant: 'white',
        backgroundColor: '#820AD1',
        headingColor: '#FFFFFF',
        subtitleColor: '#FFFFFFE0',
        textColor: '#FFFFFF',
      },
      savingsParams: {
        headingTag: 'MULTI_PRODUCT_HOME.REASONS.SAVINGS.HEADER',
        subtitleTag: 'MULTI_PRODUCT_HOME.REASONS.SAVINGS.SUBTITLE',
        textTag: 'CUCUTA.REASONS.SAVINGS.TEXT',
        buttonTag: 'MULTI_PRODUCT_HOME.REASONS.SAVINGS.BUTTON',
        buttonUrlTag: 'MULTI_PRODUCT.MENU.SAVINGS.PATH',
        buttonColor: 'black',
        buttonVariant: 'white',
        backgroundColor: '#C7AAFF',
        headingColor: '#000000F5',
        subtitleColor: '#000000A3',
        textColor: '#000000A3',
      },
      mainHeadingTag: 'MULTI_PRODUCT_HOME.REASONS.MAIN.HEADER',
    },
    perks: {
      alt: 'MULTI_PRODUCT_HOME.PERKS.ALT',
      perksImages: {
        xs: 'multi-product-home/perks/cucuta-perks-xs-2x.jpg',
        md: 'multi-product-home/perks/cucuta-perks-md-1x.jpg',
        lg: 'multi-product-home/perks/cucuta-perks-lg-1x.jpg',
      },
      firstTitle: 'MULTI_PRODUCT_HOME.PERKS.FIRST.TITLE',
      firstBody: 'MULTI_PRODUCT_HOME.PERKS.FIRST.BODY',
      secondTitle: 'MULTI_PRODUCT_HOME.PERKS.SECOND.TITLE',
      secondBody: 'MULTI_PRODUCT_HOME.PERKS.SECOND.BODY',
      thirdTitle: 'MULTI_PRODUCT_HOME.PERKS.THIRD.TITLE',
      thirdBody: 'MULTI_PRODUCT_HOME.PERKS.THIRD.BODY',
      violetTitle: 'MULTI_PRODUCT_HOME.PERKS.VIOLET.TITLE',
      backgroundColor: '#F2EEEE',
      textVariant: 'heading4',
      styleOverride: perksOverrides,
    },
    values: {
      title: 'MULTI_PRODUCT_HOME.VALUES.TITLE',
      firstBody: 'MULTI_PRODUCT_HOME.VALUES.DESCRIPTION.FIRST',
      firstTitle: 'MULTI_PRODUCT_HOME.VALUES.SUBTITLE.FIRST',
      secondBody: 'MULTI_PRODUCT_HOME.VALUES.DESCRIPTION.SECOND',
      secondTitle: 'MULTI_PRODUCT_HOME.VALUES.SUBTITLE.SECOND',
      firstButton: 'MULTI_PRODUCT_HOME.VALUES.BUTTON.FIRST',
      secondButton: 'MULTI_PRODUCT_HOME.VALUES.BUTTON.SECOND',
      firstProductLink: 'MULTI_PRODUCT_HOME.VALUES.LINK.FIRST',
      secondProductLink: 'MULTI_PRODUCT_HOME.VALUES.LINK.SECOND',
    },
    press: {
      links: [{
        summary: 'MULTI_PRODUCT_HOME.PRESS.SUMMARY.FIRST',
        linkText: 'MULTI_PRODUCT_HOME.PRESS.LINK_TEXT.FIRST',
        linkUrl: 'MULTI_PRODUCT_HOME.PRESS.LINK_URL.FIRST',
      }, {
        summary: 'MULTI_PRODUCT_HOME.PRESS.SUMMARY.SECOND',
        linkText: 'MULTI_PRODUCT_HOME.PRESS.LINK_TEXT.SECOND',
        linkUrl: 'MULTI_PRODUCT_HOME.PRESS.LINK_URL.SECOND',
      }, {
        summary: 'MULTI_PRODUCT_HOME.PRESS.SUMMARY.THIRD',
        linkText: 'MULTI_PRODUCT_HOME.PRESS.LINK_TEXT.THIRD',
        linkUrl: 'MULTI_PRODUCT_HOME.PRESS.LINK_URL.THIRD',
      },
      ],
      title: 'MULTI_PRODUCT_HOME.PRESS.TITLE',
    },
    blog: {
      alt: 'MULTI_PRODUCT_HOME.BLOG.IMG.ALT',
      blogImages: {
        xs: 'multi-product-home/blog/blog-xs-1x.jpg',
        md: 'multi-product-home/blog/blog-lg-1x.jpg',
        lg: 'multi-product-home/blog/blog-lg-1x.jpg',
      },
      title: 'MULTI_PRODUCT_HOME.BLOG.TITLE',
    },
    faq: {
      title: 'MULTI_PRODUCT_HOME.FAQ.TITLE',
      questions: [
        {
          question: 'MULTI_PRODUCT_HOME.FAQ.QUESTION.FIRST',
          answer: 'MULTI_PRODUCT_HOME.FAQ.QUESTION.ANSWER.FIRST',
          initiallyOpen: true,
        },
        {
          question: 'MULTI_PRODUCT_HOME.FAQ.QUESTION.SECOND',
          answer: 'MULTI_PRODUCT_HOME.FAQ.QUESTION.ANSWER.SECOND',
          initiallyOpen: true,
        },
        {
          question: 'MULTI_PRODUCT_HOME.FAQ.QUESTION.THIRD',
          answer: 'MULTI_PRODUCT_HOME.FAQ.QUESTION.ANSWER.THIRD',
          initiallyOpen: true,
        },
        {
          question: 'MULTI_PRODUCT_HOME.FAQ.QUESTION.FOURTH',
          answer: 'MULTI_PRODUCT_HOME.FAQ.QUESTION.ANSWER.FOURTH',
          href: 'MULTI_PRODUCT_HOME.FAQ.QUESTION.HREF.FOURTH',
          initiallyOpen: true,
        },
      ],
    },
    feedbacks: [{
      description: 'MULTI_PRODUCT_HOME.FEEDBACKS.1.DESCRIPTION',
      owner: 'MULTI_PRODUCT_HOME.FEEDBACKS.1.OWNER',
      location: 'MULTI_PRODUCT_HOME.FEEDBACKS.1.LOCATION',
    },
    {
      description: 'MULTI_PRODUCT_HOME.FEEDBACKS.2.DESCRIPTION',
      owner: 'MULTI_PRODUCT_HOME.FEEDBACKS.2.OWNER',
      location: 'MULTI_PRODUCT_HOME.FEEDBACKS.2.LOCATION',
    },
    {
      description: 'MULTI_PRODUCT_HOME.FEEDBACKS.3.DESCRIPTION',
      owner: 'MULTI_PRODUCT_HOME.FEEDBACKS.3.OWNER',
      location: 'MULTI_PRODUCT_HOME.FEEDBACKS.3.LOCATION',
    },
    {
      description: 'MULTI_PRODUCT_HOME.FEEDBACKS.4.DESCRIPTION',
      owner: 'MULTI_PRODUCT_HOME.FEEDBACKS.4.OWNER',
      location: 'MULTI_PRODUCT_HOME.FEEDBACKS.4.LOCATION',
    }],
    mgm: {
      title: 'MGM.FORM.HEAD.TITLE',
      subtitle: 'MGM.FORM.HEAD.TITLE',
      alt: 'MGM.HERO.IMAGE.ALT',
      heroImages: {
        xs: 'mgm/mgm-xs-2x.jpg',
        md: 'mgm/mgm-md-2x.jpg',
        lg: 'mgm/mgm-lg-2x.jpg',
      },
      form: {
        label: 'MULTI_PRODUCT_HOME.HERO.FORM.LABEL',
        placeholder: 'MULTI_PRODUCT_HOME.HERO.FORM.PLACEHOLDER',
        btnLabel: 'MULTI_PRODUCT_HOME.HERO.FORM.BUTTON_LABEL',
        validateDisabled: true,
      },
      specificColor: '#820AD1',
    },
  },
  multiproduct: {
    hero: {
      title: 'MULTI_PRODUCT_HOME.HERO.TITLE',
      subtitle: 'MULTI_PRODUCT_HOME.HERO.SUBTITLE',
      alt: 'MULTI_PRODUCT_HOME.HERO.IMAGE.ALT',
      heroImages: {
        xs: 'multi-product-home/hero/hero-xs-1x.jpg',
        md: 'multi-product-home/hero/hero-md-1x.jpg',
        lg: 'multi-product-home/hero/hero-lg-1x.jpg',
      },
      form: {
        label: 'MULTI_PRODUCT_HOME.HERO.FORM.LABEL',
        placeholder: 'MULTI_PRODUCT_HOME.HERO.FORM.PLACEHOLDER',
        btnLabel: 'MULTI_PRODUCT_HOME.HERO.FORM.BUTTON_LABEL',
        validateDisabled: false,
      },
      specificColor: '#820AD1',
    },
    nuMessage: {
      title: 'CREDIT_CARD.MESSAGE.TEXT',
      spanColor: '#820AD1',
    },
    disclaimer: {
      message: 'MULTI_PRODUCT_HOME.HERO.BLUR.LABEL',
      show: true,
    },
    savingsProduct: {
      sectionColor: '#F2EEEE',
      productImages: {
        xs: 'multi-product-home/products/savings/savings-xs.jpg',
        md: 'multi-product-home/products/savings/savings-md.jpg',
        lg: 'multi-product-home/products/savings/savings-lg.jpg',
      },
      header1: 'MULTI_PRODUCT_HOME.PRODUCTS.SAVINGS.HEADER1',
      header2: 'MULTI_PRODUCT_HOME.PRODUCTS.SAVINGS.HEADER2',
      text: 'MULTI_PRODUCT_HOME.PRODUCTS.SAVINGS.TEXT',
      productCardType: ProductCardType.IMAGE_RIGHT,
      btnLabel: 'MULTI_PRODUCT_HOME.PRODUCTS.SAVINGS.BUTTON',
      btnHref: 'MULTI_PRODUCT.MENU.SAVINGS.PATH',
      btnColor: 'black',
      color: '#A376FF',
      textColor: 'white',
    },
    savingsPerks: {
      alt: 'MULTI_PRODUCT_HOME.SAVINGS_VALUE_PROPOSAL.ALT',
      backGroungColor: '#F2EEEE',
      heroImages: {
        xs: 'multi-product-home/products/savings/perks/savings-perks.gif',
        md: 'multi-product-home/products/savings/perks/savings-perks.gif',
        lg: 'multi-product-home/products/savings/perks/savings-perks.gif',
      },
      title: 'MULTI_PRODUCT_HOME.SAVINGS_VALUE_PROPOSAL.TITLE',
      titleColor: 'rgba(0,0,0,0.64)',
      gridLargeOrder: '"valueProps divider picture"',
      hasDivider: true,
      styleOverride: savingsPerksOverrides,
    },
    creditsProduct: {
      productImages: {
        xs: 'multi-product-home/products/credits/creditsprd-xs.jpg',
        md: 'multi-product-home/products/credits/creditsprd-md.jpg',
        lg: 'multi-product-home/products/credits/creditsprd-lg.jpg',
      },
      header1: 'MULTI_PRODUCT_HOME.PRODUCTS.CREDITS.HEADER1',
      header2: 'MULTI_PRODUCT_HOME.PRODUCTS.CREDITS.HEADER2',
      text: 'MULTI_PRODUCT_HOME.PRODUCTS.CREDITS.TEXT',
      productCardType: ProductCardType.IMAGE_LEFT,
      btnLabel: 'MULTI_PRODUCT_HOME.PRODUCTS.CREDITS.BUTTON',
      btnHref: 'MULTI_PRODUCT.MENU.CREDIT_CARD.PATH',
      btnColor: '#820AD1',
      color: '#820AD1',
      textColor: 'white',
    },
    creditsPerks: {
      alt: 'MULTI_PRODUCT_HOME.PRODUCTS.CREDITS.PERKS.ALT',
      backGroungColor: 'white.default',
      heroImages: {
        xs: 'multi-product-home/products/credits/perks/credit-card-perks-xs-1x.gif',
        md: 'multi-product-home/products/credits/perks/credit-card-perks-xs-1x.gif',
        lg: 'multi-product-home/products/credits/perks/credit-card-perks-xs-1x.gif',
      },
      imageSize: {
        xs: 150,
        md: 300,
        lg: 300,
      },
      title: 'MULTI_PRODUCT_HOME.PRODUCTS.CREDITS.PERKS.TITLE',
      titleColor: 'primary.default',
      gridLargeOrder: '"valueProps divider picture"',
      hasDivider: true,
      styleOverride: creditPerksOverrides,
    },
    reasons: {
      creditsParams: {
        headingTag: 'MULTI_PRODUCT_HOME.REASONS.CREDITS.HEADER',
        subtitleTag: 'MULTI_PRODUCT_HOME.REASONS.CREDITS.SUBTITLE',
        textTag: 'MULTI_PRODUCT_HOME.REASONS.CREDITS.TEXT',
        buttonTag: 'MULTI_PRODUCT_HOME.REASONS.CREDITS.BUTTON',
        buttonUrlTag: 'MULTI_PRODUCT.MENU.CREDIT_CARD.PATH',
        buttonColor: 'primary',
        buttonVariant: 'white',
        backgroundColor: '#820AD1',
        headingColor: '#FFFFFF',
        subtitleColor: '#FFFFFFE0',
        textColor: '#FFFFFF',
      },
      savingsParams: {
        headingTag: 'MULTI_PRODUCT_HOME.REASONS.SAVINGS.HEADER',
        subtitleTag: 'MULTI_PRODUCT_HOME.REASONS.SAVINGS.SUBTITLE',
        textTag: 'MULTI_PRODUCT_HOME.REASONS.SAVINGS.TEXT',
        buttonTag: 'MULTI_PRODUCT_HOME.REASONS.SAVINGS.BUTTON',
        buttonUrlTag: 'MULTI_PRODUCT.MENU.SAVINGS.PATH',
        buttonColor: 'black',
        buttonVariant: 'white',
        backgroundColor: '#C7AAFF',
        headingColor: '#000000F5',
        subtitleColor: '#000000A3',
        textColor: '#000000A3',
      },
      mainHeadingTag: 'MULTI_PRODUCT_HOME.REASONS.MAIN.HEADER',
    },
    perks: {
      alt: 'MULTI_PRODUCT_HOME.PERKS.ALT',
      perksImages: {
        xs: 'multi-product-home/perks/perks-xs-2x.jpg',
        md: 'multi-product-home/perks/perks-md-2x.jpg',
        lg: 'multi-product-home/perks/perks-lg-2x.jpg',
      },
      firstTitle: 'MULTI_PRODUCT_HOME.PERKS.FIRST.TITLE',
      firstBody: 'MULTI_PRODUCT_HOME.PERKS.FIRST.BODY',
      secondTitle: 'MULTI_PRODUCT_HOME.PERKS.SECOND.TITLE',
      secondBody: 'MULTI_PRODUCT_HOME.PERKS.SECOND.BODY',
      thirdTitle: 'MULTI_PRODUCT_HOME.PERKS.THIRD.TITLE',
      thirdBody: 'MULTI_PRODUCT_HOME.PERKS.THIRD.BODY',
      violetTitle: 'MULTI_PRODUCT_HOME.PERKS.VIOLET.TITLE',
      backgroundColor: '#F2EEEE',
      textVariant: 'heading4',
      styleOverride: perksOverrides,
    },
    values: {
      title: 'MULTI_PRODUCT_HOME.VALUES.TITLE',
      firstBody: 'MULTI_PRODUCT_HOME.VALUES.DESCRIPTION.FIRST',
      firstTitle: 'MULTI_PRODUCT_HOME.VALUES.SUBTITLE.FIRST',
      secondBody: 'MULTI_PRODUCT_HOME.VALUES.DESCRIPTION.SECOND',
      secondTitle: 'MULTI_PRODUCT_HOME.VALUES.SUBTITLE.SECOND',
      firstButton: 'MULTI_PRODUCT_HOME.VALUES.BUTTON.FIRST',
      secondButton: 'MULTI_PRODUCT_HOME.VALUES.BUTTON.SECOND',
      firstProductLink: 'MULTI_PRODUCT_HOME.VALUES.LINK.FIRST',
      secondProductLink: 'MULTI_PRODUCT_HOME.VALUES.LINK.SECOND',
    },
    press: {
      links: [{
        summary: 'MULTI_PRODUCT_HOME.PRESS.SUMMARY.FIRST',
        linkText: 'MULTI_PRODUCT_HOME.PRESS.LINK_TEXT.FIRST',
        linkUrl: 'MULTI_PRODUCT_HOME.PRESS.LINK_URL.FIRST',
      }, {
        summary: 'MULTI_PRODUCT_HOME.PRESS.SUMMARY.SECOND',
        linkText: 'MULTI_PRODUCT_HOME.PRESS.LINK_TEXT.SECOND',
        linkUrl: 'MULTI_PRODUCT_HOME.PRESS.LINK_URL.SECOND',
      }, {
        summary: 'MULTI_PRODUCT_HOME.PRESS.SUMMARY.THIRD',
        linkText: 'MULTI_PRODUCT_HOME.PRESS.LINK_TEXT.THIRD',
        linkUrl: 'MULTI_PRODUCT_HOME.PRESS.LINK_URL.THIRD',
      },
      ],
      title: 'MULTI_PRODUCT_HOME.PRESS.TITLE',
    },
    blog: {
      alt: 'MULTI_PRODUCT_HOME.BLOG.IMG.ALT',
      blogImages: {
        xs: 'multi-product-home/blog/blog-xs-1x.jpg',
        md: 'multi-product-home/blog/blog-lg-1x.jpg',
        lg: 'multi-product-home/blog/blog-lg-1x.jpg',
      },
      title: 'MULTI_PRODUCT_HOME.BLOG.TITLE',
    },
    faq: {
      title: 'MULTI_PRODUCT_HOME.FAQ.TITLE',
      questions: [
        {
          question: 'MULTI_PRODUCT_HOME.FAQ.QUESTION.FIRST',
          answer: 'MULTI_PRODUCT_HOME.FAQ.QUESTION.ANSWER.FIRST',
          initiallyOpen: true,
        },
        {
          question: 'MULTI_PRODUCT_HOME.FAQ.QUESTION.SECOND',
          answer: 'MULTI_PRODUCT_HOME.FAQ.QUESTION.ANSWER.SECOND',
          initiallyOpen: true,
        },
        {
          question: 'MULTI_PRODUCT_HOME.FAQ.QUESTION.THIRD',
          answer: 'MULTI_PRODUCT_HOME.FAQ.QUESTION.ANSWER.THIRD',
          initiallyOpen: true,
        },
        {
          question: 'MULTI_PRODUCT_HOME.FAQ.QUESTION.FOURTH',
          answer: 'MULTI_PRODUCT_HOME.FAQ.QUESTION.ANSWER.FOURTH',
          href: 'MULTI_PRODUCT_HOME.FAQ.QUESTION.HREF.FOURTH',
          initiallyOpen: true,
        },
      ],
    },
    feedbacks: [{
      description: 'MULTI_PRODUCT_HOME.FEEDBACKS.1.DESCRIPTION',
      owner: 'MULTI_PRODUCT_HOME.FEEDBACKS.1.OWNER',
      location: 'MULTI_PRODUCT_HOME.FEEDBACKS.1.LOCATION',
    },
    {
      description: 'MULTI_PRODUCT_HOME.FEEDBACKS.2.DESCRIPTION',
      owner: 'MULTI_PRODUCT_HOME.FEEDBACKS.2.OWNER',
      location: 'MULTI_PRODUCT_HOME.FEEDBACKS.2.LOCATION',
    },
    {
      description: 'MULTI_PRODUCT_HOME.FEEDBACKS.3.DESCRIPTION',
      owner: 'MULTI_PRODUCT_HOME.FEEDBACKS.3.OWNER',
      location: 'MULTI_PRODUCT_HOME.FEEDBACKS.3.LOCATION',
    },
    {
      description: 'MULTI_PRODUCT_HOME.FEEDBACKS.4.DESCRIPTION',
      owner: 'MULTI_PRODUCT_HOME.FEEDBACKS.4.OWNER',
      location: 'MULTI_PRODUCT_HOME.FEEDBACKS.4.LOCATION',
    }],
    mgm: {
      title: 'MGM.FORM.HEAD.TITLE',
      subtitle: 'MGM.FORM.HEAD.TITLE',
      alt: 'MGM.HERO.IMAGE.ALT',
      heroImages: {
        xs: 'mgm/mgm-xs-2x.jpg',
        md: 'mgm/mgm-md-2x.jpg',
        lg: 'mgm/mgm-lg-2x.jpg',
      },
      form: {
        label: 'MULTI_PRODUCT_HOME.HERO.FORM.LABEL',
        placeholder: 'MULTI_PRODUCT_HOME.HERO.FORM.PLACEHOLDER',
        btnLabel: 'MULTI_PRODUCT_HOME.HERO.FORM.BUTTON_LABEL',
        validateDisabled: true,
      },
      specificColor: '#820AD1',
    },
  },
};

export default ComponentDefaultProps;
