import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

const ReasonsBelongingPurpleContainer = styled.section`
    ${breakpointsMedia({
    xs: css`
            display: grid;
            width: 100%;
            padding: 4.0rem 1.5rem;

            .subtitle1 {
                font-weight: 500;
                font-size: 36px;
                line-height: 110%;
                text-align: center;
                letter-spacing: -0.03em;
                font-feature-settings: 'ss05' on;
                color: rgba(0 0 0 / 96%);
            }
        `,
    md: css`
            padding: 72px 48px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 32px;

            .subtitle1 {
                grid-column: 1 / 3;
                width: 100%;
                font-style: normal;
                font-weight: 500;
                font-size: 48px;
                line-height: 116%;
                letter-spacing: -0.03em;
                font-feature-settings: 'tnum' on, 'lnum' on, 'ss05' on;
                color: rgba(0 0 0 / 96%);
            }
        `,
    lg: css`
            padding: 72px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 72px;

            .subtitle1 {
                grid-column: 1 / 3;
                width: 100%;
                font-style: normal;
                font-weight: 500;
                font-size: 48px;
                line-height: 116%;
                letter-spacing: -0.03em;
                font-feature-settings: 'tnum' on, 'lnum' on, 'ss05' on;
                color: rgba(0 0 0 / 96%);
            }
        `,
  })}
`;

export default ReasonsBelongingPurpleContainer;
