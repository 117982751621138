import styled, { css } from 'styled-components';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

export const StyledTypography = styled(Typography)`
  

  ${breakpointsMedia({
    xs: css`
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    `,
    md: css`
    font-size: 44px;
    line-height: 110%;
    `,
  })}

`;

StyledTypography.displayName = 'StyledTypography';
