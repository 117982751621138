import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils';

export const CopyContainer = styled.div`
  background-color: ${nuDSColor('white')};
  border-radius: 16px;
  ${breakpointsMedia({
    xs: css`
    padding: 24px;
    position: absolute;
    top: 30px;
    left: 32px;
    width: 263px;
    min-height: 200px;
    `,
    md: css`
    top: 70px;
    padding: 56px 27px 38px 56px;
    left: 72px;
    width: 595px
    `,
    lg: css`
      padding: 56px 27px 38px 56px;
      position: absolute;
      top: 128px;
      left: 144px;
      `,
  })}
`;

CopyContainer.displayName = 'CopyContainer';
