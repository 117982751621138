import styled from 'styled-components';

export const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 10rem;
  min-width: 10rem;
  position: relative;
`;

StyledSection.displayName = 'StyledSection';
