import React, { useMemo } from 'react';
import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Link from '@nubank/nuds-web/components/Link/Link';
import Image from '@nubank/nuds-web/components/Image/Image';
import Button from '@nubank/nuds-web/components/Button/Button';
import Box from '@nubank/nuds-web/components/Box/Box';

import { ProductCardContainer } from './styles/ProductCardContainer';

export const ProductCardType = {
  IMAGE_LEFT: 'LEFT',
  IMAGE_RIGHT: 'RIGHT',
};

const contentOrder = {
  LEFT: {
    pictureOrder: 1,
    textOrder: 2,
  },
  RIGHT: {
    pictureOrder: 2,
    textOrder: 1,
  },
};

const ProductCard = ({
  productImages,
  header1,
  header2,
  text,
  productCardType,
  color,
  btnLabel,
  btnHref,
  btnColor,
  textColor,
  legalImages,
  sectionColor,
}) => {
  const { formatMessage } = useIntl();
  const header2Label = useMemo(() => formatMessage({ id: header2 }));
  const header1Label = useMemo(() => formatMessage({ id: header1 }));
  const textLabel = useMemo(() => formatMessage({ id: text }));
  const btnUrl = useMemo(() => formatMessage({ id: btnHref }));

  return (
    <ProductCardContainer
      sectionColor={sectionColor}
      color={color}
      css={css`background-color: ${sectionColor} `}
      pictureOrder={contentOrder[productCardType].pictureOrder}
      textOrder={contentOrder[productCardType].textOrder}
      btnColor={btnColor}
    >
      <div className="card">
        <Image
          srcSet={productImages}
          loading="lazy"
          alt="Imagen"
        />
        <div className="product-text">
          <Typography
            color={textColor}
            variant="heading2"
            className="subtitle1"
            dangerouslySetInnerHTML={{ __html: header1Label }}
          />
          <Typography
            color={textColor}
            className="subtitle2"
            dangerouslySetInnerHTML={{ __html: header2Label }}
          />
          <Typography
            color={textColor}
            className="text"
            dangerouslySetInnerHTML={{ __html: textLabel }}
          />
          <Link href={btnUrl} css={css`width: inherit`}>
            <Button
              extended
              id="show-product-btn"
              styleVariant="white"
              variant="contained"
              css={css`color: ${textColor}`}
              intlKey={btnLabel}
              iconProps={{ name: 'arrow-right' }}
            />
          </Link>
          {legalImages && (
            <Box
              className="legal-container-images"
            >
              <Image
                src={legalImages[0]}
                loading="lazy"
                alt="Vigilado por Superintendencia Financiera de Colombia"
              />
              <Image
                src={legalImages[1]}
                loading="lazy"
                alt="Vigilado por Superintendencia Financiera de Colombia"
              />
            </Box>
          )}
        </div>
      </div>
    </ProductCardContainer>
  );
};

ProductCard.defaultProps = {
  legalImages: null,
  sectionColor: 'white',
};

ProductCard.propTypes = {
  btnColor: PropTypes.string.isRequired,
  btnHref: PropTypes.string.isRequired,
  btnLabel: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  header1: PropTypes.string.isRequired,
  header2: PropTypes.string.isRequired,
  legalImages: PropTypes.arrayOf(PropTypes.string),
  productCardType: PropTypes.string.isRequired,
  productImages: PropTypes.shape({
    lg: PropTypes.string,
    md: PropTypes.string,
    sm: PropTypes.string,
    xl: PropTypes.string,
    xs: PropTypes.string,
  }).isRequired,
  sectionColor: PropTypes.string,
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
};

export default ProductCard;
