import styled from 'styled-components';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils';
import { desktop1024, tablet768 } from '@nubank/nuds-web/styles/breakpoints';

export const Summary = styled(Typography)`

  padding-bottom: 1rem;
  text-align: center;

  && {
    margin-bottom: 0;
  }

  ${tablet768`
      border-bottom: 1px solid ${nuDSColor('black', 'defaultT20')};
    `}

  ${desktop1024`
    padding-bottom: 1.5rem;
    display: flex;
    flex-grow: 1;
    text-align: left;
    border-bottom: 1px solid ${nuDSColor('black', 'defaultT20')};
  `}
`;

Summary.displayName = 'Summary';
