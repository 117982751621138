import styled from 'styled-components';
import { tablet768, desktop1024 } from '@nubank/nuds-web/styles/breakpoints';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

export const Title = styled(Typography)`
  margin-bottom: 3rem;
  text-align: center;

  ${tablet768`
    margin-bottom: 2rem;
  `}
  ${desktop1024`
    margin-bottom: 2rem;
    text-align: left;
  `}
`;

Title.displayName = 'Title';
